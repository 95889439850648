<template>
  <b-row class="match-height">
    <b-col
      md="6"
      lg="4"
    >
      <b-card
        v-if="pedidoEnProceso"
        no-body
      >
        <b-card-body>
          <b-card-title>{{ pedidoEnProceso.cliente_nombre }}</b-card-title>
          <b-card-sub-title>{{ pedidoEnProceso.cliente_dir_calle }}, {{ pedidoEnProceso.cliente_dir_numero }}, {{ pedidoEnProceso.cliente_dir_cp }}</b-card-sub-title>
        </b-card-body>
        <!-- <b-img
          fluid
          :src="require('@/assets/images/slider/03.jpg')"
          alt="Card image cap"
        /> -->
        <b-card-body>
          <b-card-text>Distancia: <strong>{{ pedidoEnProceso.distancia }}</strong></b-card-text>
          <b-card-text>Duración: <strong>{{ pedidoEnProceso.duracion }}</strong></b-card-text>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            :href="pedidoEnProceso.mapLink"
            target="_blank"
          >
            Comenzar Trayecto
          </b-button>
          <!-- <b-link class="card-link">
            Card link
          </b-link>
          <b-link class="card-link">
            Another link
          </b-link> -->
        </b-card-body>

        <b-card-body>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            block
            :disabled="!!entregaEstado"
            @click="entregar(true)"
          >
            Entregado
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="danger"
            block
            :disabled="!!entregaEstado"
            @click="entregar(false)"
          >
            No Entregado
          </b-button>

        </b-card-body>

        <b-card-body v-if="!!entregaEstado">
          <template v-if="entregaEstado > 1">
            <b-card-text>
              Indica el motivo por el que no se pudo entregar:
            </b-card-text>
            <b-form-group>
              <b-form-radio-group
                v-model="entregaDetalles.motivoNoEntrega"
                :options="opcionesNoEntrega"
                name="radios-stacked"
                stacked
              />
            </b-form-group>
          </template>

          <b-form-textarea
            id="notas-error"
            v-model="entregaDetalles.notas"
            placeholder="Notas"
            rows="3"
            class="mb-2"
          />
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            :disabled="entregaEstado > 1 && !entregaDetalles.motivoNoEntrega"
            @click="guardarEntrega"
          >
            Guardar y Continuar
          </b-button>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BCardBody, BCardTitle, BCardSubTitle, BFormRadioGroup, BFormGroup, BFormTextarea, // BLink, // BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useRiders from './useRiders'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BFormRadioGroup,
    BFormGroup,
    BFormTextarea,
    // BLink,
    // BImg,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      getPedido,
      pedidoEnProceso,
      entregaEstado,
      entregaIncidencia,
      entregaDetalles,
      opcionesNoEntrega,
      entregar,
      guardarEntrega,
    } = useRiders()

    getPedido()

    return {
      pedidoEnProceso,
      entregaEstado,
      entregaIncidencia,
      entregaDetalles,
      opcionesNoEntrega,
      entregar,
      guardarEntrega,
    }
  },
}
</script>
