import { ref, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils/utils'

export default function useRiders() {
  const isDebug = false
  const { router } = useRouter()
  const idRider = computed(() => store.state.auth.dataUser.id)
  const fetchRutasRider = () => store.dispatch('rutas/getRutasRider', idRider.value)
  const rutasRider = computed(() => store.state.rutas.rutasRider)
  const pedidosToRuta = computed(() => store.state.rutas.pedidosToRuta)

  // Devuelve true si el pedido está entregado. En caso contrario false.
  const isEntregado = id => {
    const pedidos = pedidosToRuta.value.filter(p => p.id === id)
    if (pedidos) {
      const pedido = pedidos[0]
      const deliveredAt = pedido.delivered_at
      if (deliveredAt) return true
    }
    return false
  }

  const getRiderRutaOpen = computed(() => store.state.rutas.riderRutaOpen)
  const getRiderItemsOrdenPedidos = computed(() => {
    const result = []
    const calculated = getRiderRutaOpen.value ? getRiderRutaOpen.value.calculated : null
    if (!calculated) return result
    const items = calculated.ordenPedidos
    for (let index = 0; index < items.length; index += 1) {
      const item = items[index]
      const idCorto = item.id.split('R')[1]
      item.isEntregado = isEntregado(item.id)
      item.distancia = calculated.distancias[index].text
      item.distanciaValor = calculated.distancias[index].value
      item.duracion = calculated.duracion[index].text
      item.duracionValor = calculated.duracion[index].value
      item.title = `${item.distancia} 🚴 ${item.duracion} ⏱ ${idCorto} 🛒 ${item.cliente_nombre} 🏁`
      result.push(item)
      // if (!isEntregado(item.id)) {
      //   result.push(item)
      // }
    }
    result.push({
      isEnd: true,
      title: `${calculated.distancias[calculated.distancias.length - 1].text} 🚲 ${calculated.duracion[calculated.duracion.length - 1].text} ⌛ REGRESO A BASE 🚩`,
      distanciaValor: calculated.distancias[calculated.distancias.length - 1].value,
      duracionValor: calculated.duracion[calculated.duracion.length - 1].value,
    })
    return result
  })

  const itemsToMap = computed(() => {
    const items = getRiderItemsOrdenPedidos.value
    if (!items.length) return []
    const result = []
    // let orden = 0
    for (let orden = 0; orden < items.length - 1; orden += 1) {
      const item = items[orden]
      // items.forEach(item => {
      const {
        cliente_nombre,
        cliente_lat,
        cliente_lng,
        cliente_ruta,
        status,
        mapLink,
        id,
        cliente_dir_calle,
        cliente_dir_numero,
        cliente_dir_cp,
      } = item
      result.push({
        id,
        nombre: cliente_nombre,
        lat: cliente_lat,
        lng: cliente_lng,
        ruta: cliente_ruta,
        orden,
        status,
        mapLink,
        isEntregado: item.isEntregado,
        cliente_dir_calle,
        cliente_dir_numero,
        cliente_dir_cp,
      })
      // orden += 1
      // })
    }
    return result
  })

  const overviewPath = computed(() => {
    const rutaOpen = getRiderRutaOpen.value
    if (!rutaOpen) return []
    return rutaOpen.calculated?.overview_path || []
  })

  const getRiderItemsOrdenPedidosPendientes = computed(() => getRiderItemsOrdenPedidos.value.filter(p => !p.isEntregado))
  const getRiderItemsOrdenPedidosTerminados = computed(() => getRiderItemsOrdenPedidos.value.filter(p => !!p.isEntregado))

  const recorridoRestante = computed(() => {
    const result = {
      distanciaValor: 0,
      distancia: '0 Km',
      duracionValor: 0,
      duracion: '0 Minutos',
    }
    getRiderItemsOrdenPedidos.value.forEach(item => {
      result.distanciaValor += item.distanciaValor
      result.duracionValor += item.duracionValor
    })
    result.distancia = `${Math.round(result.distanciaValor / 1000)} Km`
    result.duracion = `${Math.round(result.duracionValor / 60)} Min`

    return result
  })

  const recorridoRestantePendientes = computed(() => {
    const result = {
      distanciaValor: 0,
      distancia: '0 Km',
      duracionValor: 0,
      duracion: '0 Minutos',
    }
    getRiderItemsOrdenPedidosPendientes.value.forEach(item => {
      result.distanciaValor += item.distanciaValor
      result.duracionValor += item.duracionValor
    })
    result.distancia = `${Math.round(result.distanciaValor / 1000)} Km`
    result.duracion = `${Math.round(result.duracionValor / 60)} Min`

    return result
  })

  const recorridoRestanteTerminados = computed(() => {
    const result = {
      distanciaValor: 0,
      distancia: '0 Km',
      duracionValor: 0,
      duracion: '0 Minutos',
    }
    getRiderItemsOrdenPedidosTerminados.value.forEach(item => {
      result.distanciaValor += item.distanciaValor
      result.duracionValor += item.duracionValor
    })
    result.distancia = `${Math.round(result.distanciaValor / 1000)} Km`
    result.duracion = `${Math.round(result.duracionValor / 60)} Min`

    return result
  })
  const rutasPendientes = computed(() => rutasRider.value.filter(r => (r.status < 15 && !r.ended_at) && !!r.calculated))
  const rutasTerminadas = computed(() => rutasRider.value.filter(r => (r.status > 7 || !!r.ended_at) && !!r.calculated))

  onMounted(() => {
    fetchRutasRider()
    // console.log(getRiderItemsOrdenPedidos.value)
  })

  const rutaOpen = ruta => {
    // Establece la ruta a iniciar
    if (ruta.status < 3) {
      console.log(ruta)
      const payload = ruta
      payload.started_ad = new Date().getTime()
      payload.status = 3
      store.dispatch('rutas/updateRuta', payload)
    }
    store.dispatch('rutas/getPedidosToRuta', ruta)
    router.push({ name: 'bimbo-delivering' })
  }

  // *===============================================---*
  // *--------- Pantalla Entragando --------------------*
  // *===============================================---*

  const pedidoEnProceso = ref(null)

  const getPedido = () => {
    const { id } = router.currentRoute.params
    // console.log(id)
    // console.log(getRiderItemsOrdenPedidos.value)
    // eslint-disable-next-line prefer-destructuring
    pedidoEnProceso.value = getRiderItemsOrdenPedidos.value.filter(p => p.id === id)[0]
    // console.log(pedidoEnProceso.value)
  }

  const opcionesNoEntrega = ref([
    { text: 'Está cerrado', value: 'isCerrado' },
    { text: 'Fuera del horario acordado', value: 'isFueraHorario' },
    { text: 'Dirección errónea', value: 'isErrorDirection' },
    { text: 'Pedido erróneo', value: 'isErrorPedido' },
    { text: 'Otro Motivo', value: 'isOtroMotivo' },
  ])
  const entregaEstado = ref(0) // 0 => No Marcado, 1 => Marcado y Entregado, 2 => Marcado y No entregado
  const entregaIncidencia = ref(false)
  const entregaDetalles = ref({
    isEntregado: undefined,
    motivoNoEntrega: '',
    notas: '',
  })

  const entregar = valor => {
    entregaEstado.value = valor ? 1 : 2
    entregaDetalles.value.isEntregado = valor
  }

  const guardarEntrega = () => {
    // entregaDetalles.value.delivered_at = new Date().getTime()
    const payload = {
      idPedido: pedidoEnProceso.value.id,
      idRuta: pedidoEnProceso.value.ruta,
      entregaDetalles: entregaDetalles.value,
    }
    store.dispatch('rutas/pedidoEntregado', payload)
    router.go(-1)
  }

  //
  const selectedMarker = marker => {
    if (isDebug) console.log(marker)
  }

  return {
    idRider,
    rutasRider,
    rutasPendientes,
    rutasTerminadas,
    getRiderRutaOpen,
    getRiderItemsOrdenPedidos,
    getRiderItemsOrdenPedidosPendientes,
    getRiderItemsOrdenPedidosTerminados,
    pedidoEnProceso,
    entregaEstado,
    entregaIncidencia,
    entregaDetalles,
    opcionesNoEntrega,
    recorridoRestante,
    recorridoRestantePendientes,
    recorridoRestanteTerminados,
    itemsToMap,
    overviewPath,
    entregar,
    guardarEntrega,
    getPedido,
    rutaOpen,
    isEntregado,
    selectedMarker,
  }
}
